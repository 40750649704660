
<template>
  <div>
    <card-loading-transparent v-if="loading"></card-loading-transparent>
    <v-card flat v-if="!loading">
      <v-card-title primary-title>
      </v-card-title>
      <v-card-text>
        <v-form ref="newForm" :readonly="!is_editable && form.id ? true : false">
          <v-text-field outlined prepend-inner-icon="mdi-card-text-outline" color="primary" 
            label="Título do formulário" v-model="form.title" :rules="required"
          ></v-text-field>
          <v-textarea rows="5" outlined prepend-inner-icon="mdi-format-list-text" color="primary" 
            label="Descrição do formulário" v-model="form.description" :rules="required"
          ></v-textarea>
          <v-select label="Categoria de curso" dense outlined v-model="form.kind"
            :items="form_kind" :item-text="'title'"  :item-value="'value'" :rules="required"
          ></v-select>
          <div v-if="false">
            <div class="primary--text subtitle-1 mb-2 d-flex justify-start">O formulário é para Inscrição / Avaliação?  </div>
            <v-radio-group v-model="form.exec_time" :rules="required">
              <v-radio label="Inscrição" value="enrollment"></v-radio>
              <v-radio label="Avaliação" value="rating"></v-radio>
            </v-radio-group>
          </div>
          <div>
            <v-switch
              v-model="form.has_personal_infos"
              flat
              :label="form.has_personal_infos ? 'O formulário exige informações pessoais' : 'O formulário não exige informações pessoais'"
            ></v-switch>
          </div>
          <div class="d-flex justify-center">
            <v-btn class="mr-3 mt-1" :color="is_editable ? 'error' : 'success'" rounded v-if="$route.params.id" @click="editForm">
              <v-icon left>{{is_editable ? 'mdi-cancel' : 'mdi-pencil'}}</v-icon> {{is_editable ? 'Cancelar' : 'Editar'}}
            </v-btn>
            <v-btn class="mr-3 mt-1" color="primary" rounded v-if="is_editable" @click="createForm">
              <v-icon left>mdi-plus</v-icon>Salvar
            </v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>

    <v-col cols="12" v-if="!loading">
      <div class="d-flex justify-center">
        <v-btn color="primary" @click="createForm()" rounded v-if="!$route.params.id">
          <v-icon left>mdi-arrow-right-bold-circle-outline</v-icon>Próximo
        </v-btn>
      </div>
    </v-col>

  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import Api from '@/api/index'
import draggable from 'vuedraggable'
import validations from '@/mixins/validations';

export default {
  name: 'HeaderForm',
    mixins: [
    validations
  ],
  data() {
    return {
      form_kind: [
        {value: 'in_person', title: 'Presencial'},
        {value: 'distance', title: 'A distância'},
        {value: 'abroad', title: 'Estrangeiro'}
      ],
      form: {
        id: null,
        title: '',
        description: '',
        exec_time: null,
        kind: null,
        has_personal_infos: false
      },

      is_editable: false,
      loading: false,
    }

  },
  methods: {
    getForm(){
      if(this.$route.params.id){
        this.loading = true
        Api.Form.show(this.$route.params.id).then(r=>r.data).then(d=> {
          setTimeout(()=>{
            this.setForm(d)
            this.$emit('setFormId', {id: d.id})
          }, 1000)
        }).catch((err) => {
          this.addNotification({type: 'error', msg: `${err}`})
          if(err.response && err.response.status && err.response.status == 404){
            this.$router.push({name: 'Forms'})
          }
        });

      }
    },
    setForm(form){
      this.form = {...form}
      this.loading = false
      //this.getItems(this.form.id)
    },
    createForm(){
      if (this.$refs.newForm.validate()) {
        this.saveDialog().then((r) => {
          if(r.isConfirmed){
            if(this.form.id == null){
              Api.Form.create(this.form).then(r => r.data).then(r => {
                this.$emit('formCreated', {tab: 1, id: r.id})
                this.form.id = r.id
                this.addNotification({type: 'success', msg: 'Realizado com sucesso'})
              }).catch(err => {
                this.addNotification({type: 'error', msg: `Erro: ${err}`})
              })
            }else{
              let form_update_aux = {
                ...this.form
              }
              Api.Form.update(form_update_aux).then(()=> {
                this.addNotification({type: 'success', msg: 'Realizado com sucesso'})
                this.is_editable = false
              }).catch(err => {
                this.addNotification({type: 'error', msg: `Erro: ${err}`})
              })
            }
          }
        })
      } 
    },
    editForm(){
      this.is_editable = !this.is_editable
      if(!this.is_editable){
        this.getForm()
      }
    },
    saveDialog(){
      return this.$swal({
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        title: 'Deseja realizar operação?',
        icon: 'question',
      })
    },

    ...mapActions({
      addNotification: 'Notification/add'
    })
  },
  computed: {
    name() {
      return null 
    },
    ...mapState({
      // data: state=> state.Module.state_var
    })
  },
  watch: {
    data(nv,ov) {
      if (nv){
      } else if (nv == false){
      }
    }
  },
  mounted() {
    this.getForm()
  },
}
</script>

<style scoped>

</style>